import React from 'react';
import './TechnologyDescription.css';
import { Typography } from 'antd';

const { Title } = Typography;

const TechnologyDescription = props => {
  const technologyStyle = {
    background:
      'linear-gradient(0deg, rgb(14 14 14 / 60%), rgb(54 49 52 / 60%)), url(' +
      props.bgImg +
      ')',
    backgroundRepeat: 'no-repeat no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  return (
    <>
      <div className='TechnologyDescription' style={technologyStyle}>
        <div className='TechnologyDescription_wrapper container    '>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='TechnologyHeading_heading'>
                <Title level={1}>
                  <span style={{ color: 'var(--primary-color)' }}>
                    {props.first_title}
                  </span>{' '}
                  <br /> {props.second_title}
                </Title>
              </div>

              <div className='hero_container_sub-heading'>
                <div
                  className='hero_container_sub-heading ant-typography tech-discription-in-tech-stack'
                  dangerouslySetInnerHTML={{
                    __html: props.description,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnologyDescription;
