import React from 'react';
import './OurStandardPractices.css';
import { OurStandardPracticeData } from '../../../../Storage/OurStandardPractices';
import Flip from 'react-reveal/Flip';
import { Divider } from 'antd';

const OurStandardPractices = () => {
  return (
    <>
      <div id='OurStandardPractices'>
        <div className='OurStandardPractices_wrapper container'>
          <div className='OurStandardPractices_heading'>Process We Follow</div>
          <Divider />
          <div className='OurStandardPractices_sub-heading'>
            Right From the Beginning of Project till its delivery. Remember us
            for Quality Product Delivery.
          </div>

          <div className='ant-row'>
            {' '}
            {OurStandardPracticeData.map(e => (
              <div
                className='ant-col ant-col-xs-12 ant-col-sm-8  ant-col-md-6 ant-col-lg-4 ant-col-xl-4'
                key={e.id}
              >
                <Flip top duration={2500}>
                  <div className='OurStandardPractices_card'>
                    <div className='OurStandardPractices_card_img'>
                      <img src={e.iconUrl} alt='' />
                    </div>
                    <div className='OurStandardPractices_card_text'>
                      {e.title}
                    </div>
                  </div>
                </Flip>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStandardPractices;
