import React from 'react';
import './CareerHero.css';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import CareerPeople from '../../../../assets/Career/career_people.png';
import { scroller } from 'react-scroll';

const { Title } = Typography;

const CareerHero = () => {
  const scrollToSection = () => {
    scroller.scrollTo('SearchJobs_career', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -70,
    });
  };
  return (
    <>
      <div className='CareerHero'>
        <div className='CareerHero_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='CareerHero_heading'>
                <Title level={1}>Make the most out of your Skills.</Title>
              </div>

              <div className='hero_container_sub-heading'>
                <Title level={5}>
                  Join a team where you can explore your skills and create{' '}
                  <br /> an Impact in the technology.
                </Title>
              </div>
              <div className='hero_container_buttons'>
                <Link
                  to='#'
                  onClick={event => {
                    // event.preventDefault();
                    scrollToSection();
                  }}
                >
                  <div className='hero-contact-btn'>View Openings &#8594;</div>
                </Link>
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='CareerHero_banner_img'>
                <img src={CareerPeople} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerHero;
