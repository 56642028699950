import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Autoplay } from 'swiper';
const MacbookSlider = () => {
  return (
    <div className='macbook_slider'>
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={true}
        speed={3000}
        autoplay={{
          delay: 2500,
          // disableOnInteraction: false,
        }}
        navigation={false}
        modules={[Pagination, Autoplay]}
        className='mySwiper'
      >
        <SwiperSlide>
          <img src='https://picsum.photos/600/400' alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src='https://picsum.photos/600/400' alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src='https://picsum.photos/600/400' alt='' />
        </SwiperSlide>
        <SwiperSlide>
          <img src='https://picsum.photos/600/400' alt='' />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MacbookSlider;
