import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import './ContactForm.css';
import { UserOutlined } from '@ant-design/icons';
import { BsTelephone } from 'react-icons/bs';
import { IoMailOutline } from 'react-icons/io5';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const { Option } = Select;

const CoreContactFrom = () => {
  const [form] = Form.useForm();
  const [stackData, setStackData] = useState(null);
  const [isSending, setIsSending] = useState(false);

  // const ContactUrl = `${process.env.REACT_APP_API_KEY}/contact`;
  const ContactUrl = `http://localhost:3000/v1/contact`;
  const recaptchaRef = useRef(null);

  const onFinish = async values => {
    setIsSending(true);
    console.log('Received values of form: ', values);
    // const captchaToken = await recaptchaRef.current.executeAsync();
    // recaptchaRef.current.reset();

    await axios
      .post(ContactUrl, {
        name: values.name,
        phone: values.phone,
        email: values.email,
        interest: values.interest,
        message: values.message,
        // captchaToken,
        // request_type: checkboxValue,
      })
      .then(response => {
        // showTelegramBtn();
        message.success('Message submitted successfully.');
        form.resetFields();
        setIsSending(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        message.error('Message sending failed!');
        setIsSending(false);
      })
      .finally(setIsSending(false));
  };

  const handleOnChange = event => {
    console.log('triggered');
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setStackData(res.data);
    });
  }, []);
  return (
    <div>
      <Form
        form={form}
        name='contact form'
        initialValues={{
          remember: true,
        }}
        layout='vertical'
        requiredMark={false}
        onFinish={onFinish}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size='invisible'
        />
        <div className='ant-row'>
          <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-xl-12 '>
            <Form.Item
              label='Name:'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Please enter your full valid name!',
                  pattern: new RegExp(
                    /^[a-zA-Z@~ `!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                },
                {
                  min: 3,
                  message: 'username must be minimum 3 characters.',
                },
                {
                  max: 25,
                  message: 'username cannot be more than 25 characters.',
                },
              ]}
              style={{
                display: 'inline-block',
                width: 'calc(100% - 0px)',
              }}
            >
              <Input
                placeholder='Your Full Name'
                prefix={<UserOutlined className='site-form-item-icon' />}
              />
            </Form.Item>
          </div>

          <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-xl-12'>
            <Form.Item
              label='Your Phone:'
              validateTrigger='onBlur'
              hasFeedback
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone!',
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (isNaN(value)) {
                      return Promise.reject('Phone has to be a number.');
                    }
                    if (value.length < 10) {
                      return Promise.reject(
                        "Phone code can't be less than 10 digits"
                      );
                    }
                    if (value.length > 10) {
                      return Promise.reject(
                        "Phone code can't be more than 10 digits"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{
                display: 'inline-block',
                width: 'calc(100% - 0px)',
              }}
            >
              <Input placeholder='Your Phone' prefix={<BsTelephone />} />
            </Form.Item>
          </div>

          <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-xl-12'>
            <Form.Item
              label='Email:'
              name='email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please enter your email!',
                },
              ]}
              style={{
                display: 'inline-block',
                width: 'calc(100% - 0px)',
              }}
            >
              <Input placeholder='Your Email' prefix={<IoMailOutline />} />
            </Form.Item>
          </div>

          <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-xl-12'>
            <Form.Item
              label='Select your interest'
              name='interest'
              rules={[
                {
                  required: true,
                  message: 'Please select your interest!',
                },
              ]}
            >
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                showSearch
                style={{ zIndex: 1 }}
                placeholder='I’m interested in...'
                optionFilterProp='children'
                onChange={handleOnChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {stackData &&
                  stackData.map(e => (
                    <Option value={e.title} key={e.id}>
                      {e.title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

          <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
            <Form.Item
              label='Message:'
              name='message'
              rules={[
                {
                  required: true,
                  message: 'Please write your message!',
                },
              ]}
            >
              <Input.TextArea rows={3} placeholder='Your Message' />
            </Form.Item>
          </div>

          <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                loading={isSending}
                block
                size='large'
                style={{
                  background: 'var(--primary-color)',
                  borderColor: 'var(--primary-color)',
                }}
              >
                Send Message
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CoreContactFrom;
