import React from 'react';
import './RecruitmentProcess.css';
import CV from '../../../../assets/Career/cv.png';
import Goals from '../../../../assets/Career/goals.png';
import Interview from '../../../../assets/Career/interview.png';
import Phone from '../../../../assets/Career/phone.png';

const RecruitmentProcess = () => {
  return (
    <>
      <div className='RecruitmentProcess'>
        <div className='RecruitmentProcess_wrapper container'>
          <div className='RecruitmentProcess_heading'>
            Learn Our Recruitment{' '}
            <span style={{ color: 'var(--primary-color)' }}> Process </span>
          </div>
          <div className='RecruitmentProcess_cards'>
            <div className='ant-row'>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='RecruitmentProcess_card'>
                  <div className='RecruitmentProcess_card_wrapper'>
                    <div className='RecruitmentProcess_card_icon'>
                      <img src={CV} alt='' />
                    </div>
                    <div className='RecruitmentProcess_card_title'>
                      Submit Your CV
                    </div>
                    <div className='RecruitmentProcess_card_text'>
                      We will look into your CV and your work profile. Make sure
                      to send us an updated CV
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------- */}

              <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='RecruitmentProcess_card'>
                  <div className='RecruitmentProcess_card_wrapper'>
                    <div className='RecruitmentProcess_card_icon'>
                      <img src={Goals} alt='' />
                    </div>
                    <div className='RecruitmentProcess_card_title'>
                      Phone Interview
                    </div>
                    <div className='RecruitmentProcess_card_text'>
                      We wil have a telephonic interview and get to know about
                      you and your work profile.
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------- */}

              <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='RecruitmentProcess_card'>
                  <div className='RecruitmentProcess_card_wrapper'>
                    <div className='RecruitmentProcess_card_icon'>
                      <img src={Interview} alt='' />
                    </div>
                    <div className='RecruitmentProcess_card_title'>
                      Interview Schedule
                    </div>
                    <div className='RecruitmentProcess_card_text'>
                      We will invite you for an interview depending upon the
                      nature and expertise of the job role.
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------- */}

              <div className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='RecruitmentProcess_card'>
                  <div className='RecruitmentProcess_card_wrapper'>
                    <div className='RecruitmentProcess_card_icon'>
                      <img src={Phone} alt='' />
                    </div>
                    <div className='RecruitmentProcess_card_title'>
                      Selection Status
                    </div>
                    <div className='RecruitmentProcess_card_text'>
                      After the final interview we will let you know whether you
                      have been selected or not.
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------- */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruitmentProcess;
