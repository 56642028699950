import React, { useState, useEffect } from 'react';
import './Footer.css';
import Logo from '../../../assets/logo.png';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { LetsConnect } from '../../UI';
import axios from 'axios';

const Footer = () => {
  const [data, setData] = useState([]);
  const [stackData, setStackData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/web-settings`).then(res => {
      setData(res.data[0]);
    });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setStackData(res.data);
    });
  }, []);

  return (
    <>
      <div id='Footer'>
        <LetsConnect />

        <div className='footer_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              <div className='footer_logo'>
                <img src={Logo} alt='' />
              </div>
              <div className='footer_heading'>Main Office</div>
              <div className='footer_sub_heading'> Radhe Radhe </div>
              <div className='footer_sub_heading'> Bhaktapur</div>
              <div className='footer_sub_heading'> Nepal</div>
            </div>
            <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              <div className='footer_heading'>Services</div>

              {stackData &&
                stackData.slice(0, 6).map(e => (
                  <div className='footer_sub_heading' key={e._id}>
                    <Link
                      to={
                        `/technology/` +
                        e.title.replace(/\s+/g, '-').toLowerCase()
                      }
                    >
                      {' '}
                      {e.title}
                    </Link>
                  </div>
                ))}
            </div>
            <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
              <div className='footer_heading'>Company</div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/about'> Who We Are </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/services'> What We Do </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/career'> Career </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/contact'> Contact </Link>
              </div>
              <div className='footer_sub_heading'>
                {' '}
                <Link to='/result'> Application Result </Link>
              </div>
            </div>
            {data !== null ? (
              <div className='ant-col ant-col-xs-12 ant-col-sm-12  ant-col-md-6 ant-col-lg-6 ant-col-xl-6'>
                <div className='footer_heading'>Connect With Us</div>
                <div className='footer_sub_heading footer_facebook_icon'>
                  {' '}
                  <a href={data.facebook_link} target='_blank' rel='noreferrer'>
                    <FaFacebook /> Facebook{' '}
                  </a>
                </div>
                <div className='footer_sub_heading footer_instagram_icon'>
                  <a
                    href={data.instagram_link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaInstagram /> Instagram{' '}
                  </a>
                </div>
                <div className='footer_sub_heading footer_linkedin_icon'>
                  <a href={data.linkedIn_link} target='_blank' rel='noreferrer'>
                    <FaLinkedin /> LinkedIn{' '}
                  </a>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
