import React, { useState, useEffect } from 'react';
import './BlogPage.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { Skeleton } from 'antd';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs/${id}`).then(res => {
      // axios.get(`http://localhost:3000/v1/blogs/${id}`).then(res => {
      setData(res.data);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs`).then(res => {
      // axios.get(`http://localhost:3000/v1/blogs/`).then(res => {
      setAllData(res.data);
    });
  }, []);

  return (
    <div id='BlogPage'>
      <Helmet>
        <title>{data?.title}</title>
        <meta
          name='description'
          content={
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          }
          data-react-helmet='true'
        />
        <meta
          name='keywords'
          content='Business software development companies ,software development company,outsourcing software development companies, offshore software development, software outsourcing company, software outsourcing companies in uae, software outsourcing companies in usa, software outsourcing companies in australia, software outsourcing companies in Nepal, software projects outsourcing companies in australia, software projects outsourcing companies in Nepal, software projects outsourcing companies in usa, software projects outsourcing companies in Nepal, it outsourcing companies in uae, it outsourcing companies in usa, it outsourcing companies in Nepal, it outsourcing companies in australia, software development companies in usa, software development companies in uae, software development companies in Nepal, software development companies in australia, software companies, it companies dubai uae, mobile app development companies in uae, mobile app development companies in usa, mobile app development companies in australia, mobile app development companies in Nepal, mobile application development companies in uae, mobile application development companies in usa, mobile application development companies in Nepal, mobile application development companies in australia, best software development companies in uae, android application development companies in usa, mobile app development companies, app development companies india, software development, web application development, web development company, software outsourcing, business solutions, app development companies, mobile application development, android app development, school software solutions, it companies, software development dubai, software development uae'
          data-react-helmet='true'
        />

        <meta
          property='og:image'
          content={data?.image}
          data-react-helmet='true'
        />
      </Helmet>

      <div className='BlogPage_wrapper container'>
        <div className='ant-row'>
          <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-17 ant-col-xl-17'>
            <div className='BlogPage_heading'>{data?.title}</div>

            {data === null ? (
              <Skeleton active style={{ padding: '15px' }} />
            ) : (
              <>
                <div className='BlogPage_heading_text'>
                  <p style={{ textTransform: 'capitalize' }}>
                    <Moment format='DD MMM, YYYY'>{data?.date}</Moment> |{' '}
                    {data?.category} | AITC |
                  </p>
                </div>
                <div className='BlogPage_image'>
                  <div
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  />
                </div>
              </>
            )}
          </div>

          <div
            className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-7 ant-col-xl-7'
            style={{ marginTop: '100px', marginBottom: '130px' }}
          >
            {/* next blog */}

            {allData &&
              allData
                .filter(
                  name => name.title !== undefined && name.id !== Number(id)
                )
                .slice(0, 6)
                .sort((a, b) => b.date - a.date)
                .map(e => (
                  <Link
                    to={`/article/${e.title
                      .replace(/\s+/g, '-')
                      .replace(/\./g, '')
                      .replace(/;/g, '')
                      .toLowerCase()}-${e.id}`}
                    key={e.id}
                  >
                    <div className='sidebar-blogs'>
                      <div className='blog-sidebar-img'>
                        <img
                          src={
                            `${process.env.REACT_APP_API_KEY_WITHOUT_V1}/` +
                              e.image || 'https://via.placeholder.com/150'
                          }
                          alt=''
                          height={130}
                          width={180}
                        />
                      </div>

                      <div className='blog-sidebar-heading'>{e.title}</div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
