import React, { useState } from 'react';
import { Form, Input, Button, Select, message, Steps } from 'antd';
import './ContactForm.css';
import { UserOutlined } from '@ant-design/icons';
import { BsTelephone, BsCompass } from 'react-icons/bs';
import { IoMailOutline } from 'react-icons/io5';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { DevelopmentStack } from '../../../../Storage/DevelopmentStack';
import CoreContactFrom from './CoreContactFrom';

const { Option } = Select;

const ContactForm = () => {
  const [form] = Form.useForm();
  const [isSending, setIsSending] = useState(false);

  const ContactUrl = `${process.env.REACT_APP_API_KEY}/contact`;

  const onFinish = values => {
    setIsSending(true);

    axios
      .post(ContactUrl, {
        name: values.name,
        phone: values.phone,
        email: values.email,
        interest: values.interest,
        message: values.message,
        // request_type: checkboxValue,
      })
      .then(response => {
        // showTelegramBtn();
        message.success('Message submitted successfully.');
        form.resetFields();
        setIsSending(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        message.error('Message sending failed!');
      });
  };

  const handleOnChange = event => {
    console.log('triggered');
  };

  return (
    <>
      <div id='ContactForm'>
        <div className='ContactForm_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='contacts_contact_card '>
                <div className='contacts_contact_card_wrapper'>
                  <div className='have_a_project_heading'>Have a Project?</div>
                  <div className='have_a_project_subheading'>
                    We’d Love to Hear From You.
                  </div>
                  <div className='contact_text_heading'>Address</div>
                  <div className='contact_text_subheading'>
                    Radhe Radhe, Bhaktapur 44800, Nepal
                  </div>

                  <div className='contact_text_heading'>Phone</div>
                  <div className='contact_text_subheading'>01-5900970</div>

                  <div className='contact_text_heading'>Email</div>
                  <div className='contact_text_subheading'>
                    info@aitcentre.com
                  </div>

                  <div className='career_text'>
                    Are you looking to join us? Feel free to drop us a note:
                    <span style={{ color: 'var(--primary-color)' }}>
                      {' '}
                      career@aitcentre.com{' '}
                    </span>
                    <br />
                    <Link
                      to='/career'
                      style={{ color: 'var(--primary-color)' }}
                    >
                      {' '}
                      See all openings &#8594;
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <CoreContactFrom />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
