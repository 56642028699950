import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logo from '../../../assets/logo.png';
import MobileNavbar from './MobileNavbar';
import { Menu, Dropdown, Affix } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';

const Navbar = () => {
  const [top] = useState(0);
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
    });
  }, []);

  const Services_Dropdown = (
    <div className='container'>
      <Menu style={{ width: '320px' }}>
        <div className='arrow-up'></div>
        <div className='ant-row  navbar-dropdown-menu'>
          <div className='ant-col ant-col-md-24 ant-col-lg-24 ant-col-xl-24 service_list'>
            <ul>
              {data &&
                data
                  .filter(filteredData => filteredData.allow_in_menu === 'true')
                  .map(e => (
                    <Link
                      to={
                        `/technology/` +
                        e.title.replace(/\s+/g, '-').toLowerCase()
                      }
                      key={e._id}
                    >
                      {' '}
                      <li>
                        {/* <img src={e.dropdownIcon} alt='' /> | */}
                        {e.title}
                      </li>{' '}
                    </Link>
                  ))}
            </ul>
          </div>
        </div>
      </Menu>
    </div>
  );

  return (
    <div className='top_navbar'>
      <Affix offsetTop={top}>
        <div className='top_navbar_wrapper'>
          <div id='NAVBAR'>
            <header className='container'>
              <div className='navbar-items'>
                <div className='logo'>
                  <Link to='/'>
                    <img alt={'logo'} src={Logo} />
                  </Link>
                </div>

                <div className='desktop-nav-links_item'>
                  <ul>
                    <li>
                      <Link to={'/'}> Home </Link>
                    </li>
                    <li>
                      <Link to={'/about'}> About Us</Link>
                    </li>
                    <li>
                      <Dropdown
                        overlay={Services_Dropdown}
                        placement='bottomCenter'
                        getPopupContainer={triggerNode =>
                          triggerNode.parentNode
                        }
                      >
                        <Link to={'/services'}>
                          Services <DownOutlined size={27} />
                        </Link>
                      </Dropdown>
                    </li>
                    <li>
                      <Link to={'/career'}>Career</Link>
                    </li>
                    <li>
                      <Link to={'/blog'}>Blog</Link>
                    </li>
                    <li>
                      <div className='navbar_hero_container_button'>
                        <Link to='/contact'>
                          <div className='hero-contact-btn'>Let's Connect</div>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
          </div>
          <MobileNavbar />
        </div>
      </Affix>
    </div>
  );
};

export default Navbar;
