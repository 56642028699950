import React from 'react';
import './ServiceCard.css';
import { Link } from 'react-router-dom';

const ServiceCard = props => {
  //   const mystyle = {
  //     background: {props.bg},
  //   };

  return (
    <>
      <div className='ServiceCard'>
        <div className='ServiceCard_wrapper' style={{ background: props.bg }}>
          <Link to={props.link}>
            <div className='ServiceCard_img'>
              <lottie-player
                autoplay
                loop
                mode='normal'
                src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${props.HeaderImg}`}
                style={{ height: '150px' }}
              ></lottie-player>
            </div>
            <div className='ServiceCard_title'> {props.title}</div>
            <div className='ServiceCard_more_link'>Learn more &#8594;</div>{' '}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
