import React from 'react';
import {
  CareerHero,
  CounterContainer,
  OurValues,
  RecruitmentProcess,
  SearchJobs,
} from '../../components/UI';
import './Career.css';
import { Helmet } from 'react-helmet';

const Career = () => {
  return (
    <>
      <Helmet>
        <title>AITC | IT Company based in Bhaktapur Amazing IT Centre</title>
        <meta
          name='description'
          content='AITC is an amazing IT company with technical and experienced professionals who have extensive experience in providing software and services on a global scale.'
          data-react-helmet='true'
        />
        <meta
          name='keywords'
          content='Business software development companies ,software development company,outsourcing software development companies, offshore software development, software outsourcing company, software outsourcing companies in uae, software outsourcing companies in usa, software outsourcing companies in australia, software outsourcing companies in Nepal, software projects outsourcing companies in australia, software projects outsourcing companies in Nepal, software projects outsourcing companies in usa, software projects outsourcing companies in Nepal, it outsourcing companies in uae, it outsourcing companies in usa, it outsourcing companies in Nepal, it outsourcing companies in australia, software development companies in usa, software development companies in uae, software development companies in Nepal, software development companies in australia, software companies, it companies dubai uae, mobile app development companies in uae, mobile app development companies in usa, mobile app development companies in australia, mobile app development companies in Nepal, mobile application development companies in uae, mobile application development companies in usa, mobile application development companies in Nepal, mobile application development companies in australia, best software development companies in uae, android application development companies in usa, mobile app development companies, app development companies india, software development, web application development, web development company, software outsourcing, business solutions, app development companies, mobile application development, android app development, school software solutions, it companies, software development dubai, software development uae'
          data-react-helmet='true'
        />

        <meta
          property='og:image'
          content='https://demo.aitcentre.com/logo512.png'
          data-react-helmet='true'
        />
      </Helmet>
      <div className='career_page'>
        <CareerHero />
        <OurValues />
        <SearchJobs />
        <CounterContainer />
        <RecruitmentProcess />
      </div>
    </>
  );
};

export default Career;
