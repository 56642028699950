import React, { useState, useEffect } from 'react';
import OurServiceCard from './OurServiceCard.jsx/OurServiceCard';
import './OurServices.css';
import axios from 'axios';
import Particles from 'react-tsparticles';
import { Divider } from 'antd';

const OurServices = () => {
  const [data, setData] = useState(null);
  const [webSetting, setWebSetting] = useState(null);
  const [lottiData, setLottiData] = useState(null);

  const particlesInit = main => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = container => {};
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
      setLottiData(res.data.HomeJsonIllustration);
    });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/web-settings`).then(res => {
      setWebSetting(res.data);
    });
  }, []);
  return (
    <>
      <div className='our_services_component'>
        <Particles
          id='tsparticles_our_services_component'
          init={particlesInit}
          height='250px'
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: '#fff',
              },
              // position: '50% 50%',
              repeat: 'no-repeat',
              size: 'cover',
            },
            fullScreen: {
              enable: false,
            },
            interactivity: {
              events: {
                onClick: {
                  mode: 'push',
                },
                onDiv: {
                  selectors: '#repulse-div',
                  mode: 'repulse',
                },
                onHover: {
                  mode: 'bubble',
                  parallax: {
                    force: 60,
                  },
                },
              },
              modes: {
                bubble: {
                  distance: 10,
                  duration: 2,
                  opacity: 0.8,
                  size: 10,
                },
                grab: {
                  distance: 400,
                },
              },
            },
            particles: {
              color: {
                value: '#ffffff',
              },
              links: {
                color: {
                  value: '#000',
                },
                distance: 150,
                opacity: 0.4,
              },
              move: {
                attract: {
                  distance: 100,
                  rotate: {
                    x: 600,
                    y: 1200,
                  },
                },
                enable: true,
                path: {},
                outModes: {
                  bottom: 'out',
                  left: 'out',
                  right: 'out',
                  top: 'out',
                },
                spin: {},
              },
              number: {
                density: {
                  enable: true,
                },
                value: 5,
              },
              opacity: {
                random: {
                  enable: true,
                },
                value: {
                  min: 0.1,
                  max: 1,
                },
                animation: {
                  enable: true,
                  speed: 1,
                  minimumValue: 0.2,
                },
              },
              rotate: {
                random: {
                  enable: true,
                },
                animation: {
                  enable: true,
                  speed: 5,
                },
                direction: 'random',
              },
              shape: {
                options: {
                  character: {
                    fill: false,
                    font: 'Verdana',
                    style: '',
                    value: '*',
                    weight: '400',
                  },
                  char: {
                    fill: false,
                    font: 'Verdana',
                    style: '',
                    value: '*',
                    weight: '400',
                  },
                  polygon: {
                    sides: 5,
                  },
                  star: {
                    sides: 5,
                  },
                  image: [
                    {
                      src: 'https://assets.dryicons.com/uploads/icon/svg/9857/1a9bd985-9072-460a-8a46-24bf9f73f496.svg',

                      width: 32,
                      height: 32,
                    },
                    {
                      src: 'https://assets.dryicons.com/uploads/icon/svg/12771/05d5d1fc-ec6f-4422-99b6-36e0ea8209b0.svg',

                      width: 32,
                      height: 32,
                    },
                    {
                      src: 'https://assets.dryicons.com/uploads/icon/svg/9177/f9a07fd4-0336-46ed-9173-5ec0098a492c.svg',

                      width: 32,
                      height: 32,
                    },

                    {
                      src: 'https://assets.dryicons.com/uploads/icon/svg/6556/4d0db339-2f08-4881-8a4f-4ba7a3ced97a.svg',

                      width: 32,
                      height: 32,
                    },

                    {
                      src: 'https://assets.dryicons.com/uploads/icon/svg/3102/joystick.svg',

                      width: 32,
                      height: 32,
                    },
                    {
                      src: 'https://assets.dryicons.com/uploads/icon/svg/9177/f9a07fd4-0336-46ed-9173-5ec0098a492c.svg',

                      width: 32,
                      height: 32,
                    },
                  ],
                },
                type: 'image',
              },
              size: {
                value: 10,
                animation: {
                  speed: 40,
                  minimumValue: 0.1,
                },
              },
              stroke: {
                color: {
                  value: '#000000',
                  animation: {
                    h: {
                      count: 0,
                      enable: false,
                      offset: 0,
                      speed: 1,
                      sync: true,
                    },
                    s: {
                      count: 0,
                      enable: false,
                      offset: 0,
                      speed: 1,
                      sync: true,
                    },
                    l: {
                      count: 0,
                      enable: false,
                      offset: 0,
                      speed: 1,
                      sync: true,
                    },
                  },
                },
              },
            },
          }}
        />
        <div className='landing_page_component container'>
          <div className='landing_page_component_wrapper container'>
            <div className='landing_page_component_title'>Our Services</div>
            <Divider />
            <div className='landing_page_component_description'>
              {webSetting === null
                ? webSetting
                : webSetting[0].homepage_our_service_sub_heading}{' '}
            </div>
            <div className='landing_page_component_content'>
              <div className='ant-row'>
                {data &&
                  data.map(e => (
                    <div
                      className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-8 ant-col-lg-6 ant-col-xl-6'
                      key={e._id}
                    >
                      <OurServiceCard
                        key={e._id}
                        animationData={
                          process.env.REACT_APP_API_KEY_WITHOUT_V1 +
                          '/' +
                          e.HomeJsonIllustration
                        }
                        Service_title={e.title}
                        description={e.short_description}
                        link={
                          `/technology/` +
                          e.title.replace(/\s+/g, '-').toLowerCase()
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
