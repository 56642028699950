import React, { useState, useEffect } from 'react';
import IndividualCard from '../IndividualCard/IndividualCard';
import './OurTeamSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import axios from 'axios';

import { Autoplay, Pagination, Navigation, EffectCards } from 'swiper';
const OurTeamSlider = () => {
  const [peopleData, setPeopleData] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/our-team`).then(res => {
      setPeopleData(res.data);
    });
  }, []);
  return (
    <>
      <div className='OurTeamSlider'>
        <div className='OurTeamSlider_heading'>
          Team <span style={{ color: 'var(--primary-color)' }}> AITC </span>
        </div>

        <div className='OurTeamSlider_subheading'>
          Meet the Exceptional Team Behind AITC
        </div>
        <div className='OurTeamSlider_wrapper container'>
          {/* <IndividualCard /> */}

          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              390: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              552: {
                slidesPerView: 2,
                spaceBetween: 20,
              },

              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className='mySwiper'
          >
            {peopleData &&
              peopleData.map(e => (
                <SwiperSlide>
                  <IndividualCard
                    name={e.name}
                    position={e.position}
                    description={e.description}
                    profile_pic={
                      process.env.REACT_APP_API_KEY_WITHOUT_V1 + '/' + e.image
                    }
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        <div className='OurTeamSlider_mobile'>
          <Swiper
            effect={'cards'}
            grabCursor={true}
            modules={[EffectCards]}
            className='mySwiper'
            loop={true}
            // autoplay={true}
            autoplay={{
              delay: 100,
              disableOnInteraction: false,
            }}
          >
            {peopleData &&
              peopleData
                .sort((a, b) => a.id - b.id)
                .map(e => (
                  <SwiperSlide>
                    <IndividualCard
                      name={e.name}
                      position={e.position}
                      description={e.description}
                      profile_pic={
                        process.env.REACT_APP_API_KEY_WITHOUT_V1 + '/' + e.image
                      }
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default OurTeamSlider;
