import React, { useState, useRef } from 'react';
import './JobDetails.css';
import {
  Form,
  Input,
  Button,
  message,
  Upload,
  Steps,
  InputNumber,
  Result,
  DatePicker,
} from 'antd';
import axios from 'axios';
import { FaUserGraduate, FaSchool } from 'react-icons/fa';
import { GrUserManager } from 'react-icons/gr';
import { UserOutlined, SmileOutlined } from '@ant-design/icons';
import { BsTelephone, BsGithub } from 'react-icons/bs';
import {
  IoMailOutline,
  IoLogoLinkedin,
  IoCodeWorkingOutline,
} from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const { TextArea } = Input;
const { Step } = Steps;
const steps = [
  {
    title: 'First',
    content: 'First-page',
  },
  {
    title: 'Second',
    content: 'Second-page',
  },
  {
    title: 'Last',
    content: 'Last-page',
  },
];
const ApplyJobForm = () => {
  const [form] = Form.useForm();
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState(null);
  const [current, setCurrent] = useState(0);
  const { name } = useParams();
  const recaptchaRef = useRef(null);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async values => {
    setIsSending(true);

    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    let data = new FormData();
    data.append('document', values.document.fileList[0].originFileObj);
    data.append('name', values.name);
    data.append('captchaToken', captchaToken);
    data.append('applied_for', name);
    data.append('phone', values.phone);
    data.append('email', values.email);
    data.append('highest_degree', values.highest_degree);
    data.append('institution', values.institution);
    data.append('graduation_year', values.graduation_year);
    data.append('linkedin_url', values.linkedin_url);
    data.append('github_url', values.github_url);
    data.append('experience', values.experience);
    data.append('designation', values.designation);
    data.append('prof_skills', values.prof_skills);
    data.append('most_used_tools', values.most_used_tools);
    data.append('cover_letter', values.message.fileList[0].originFileObj);

    await axios
      .post(
        process.env.REACT_APP_API_KEY + '/vacancy-data/',
        // 'http://localhost:3000/v1' + '/vacancy-data/',

        data,
        { timeout: 30000 }
      )
      .then(response => {
        message.success('Your details has been submitted successfully.', 20);
        form.resetFields();
        setIsSending(false);
      })
      .catch(error => {
        message.error('Something went wrong. Please try again later.', 10);
        setIsSending(false);
        console.log(error);
      })
      .finally(setIsSending(false));

    // .catch(function (response) {
    //   console.log(response);
    //   setIsSending(false);
    //   message.error('Unable to submit details!', response);
    // })
    //
  };

  const props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },

    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <Form
        form={form}
        name='Apply_job_form'
        initialValues={{
          remember: true,
        }}
        layout='vertical'
        requiredMark={false}
        onFinish={onFinish}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size='invisible'
        />
        <div className='ant-row'>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className='steps-content'>
            <div
              className={steps[current].content === 'First-page' ? '' : 'hide'}
            >
              <div
                className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24 '
                style={{ marginTop: '20px' }}
              >
                <Form.Item
                  label='Full Name:'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your full valid name!',
                      pattern: new RegExp(
                        /^[a-zA-Z@~ `!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                    },
                    {
                      min: 3,
                      message: 'username must be minimum 3 characters.',
                    },
                    {
                      max: 25,
                      message: 'username cannot be more than 25 characters.',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input
                    placeholder='Your Full Name'
                    prefix={<UserOutlined className='site-form-item-icon' />}
                  />
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Your Phone:'
                  validateTrigger='onBlur'
                  hasFeedback
                  name='phone'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone!',
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject('Phone has to be a number.');
                        }
                        if (value.length < 10) {
                          return Promise.reject(
                            "Phone code can't be less than 10 digits"
                          );
                        }
                        if (value.length > 10) {
                          return Promise.reject(
                            "Phone code can't be more than 10 digits"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input placeholder='Your Phone' prefix={<BsTelephone />} />
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Email:'
                  name='email'
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Please enter your email!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input placeholder='Your Email' prefix={<IoMailOutline />} />
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='LinkedIn URL:'
                  name='linkedin_url'
                  rules={[
                    {
                      type: 'url',
                      required: true,
                      message: 'Please enter your linkedIn url!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input
                    placeholder='LinkedIn URL'
                    prefix={<IoLogoLinkedin />}
                  />
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Github URL:'
                  name='github_url'
                  rules={[
                    {
                      type: 'url',
                      required: true,
                      message: 'Please enter your github url!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input placeholder='Github URL' prefix={<BsGithub />} />
                </Form.Item>
              </div>
            </div>

            <div
              className={steps[current].content === 'Second-page' ? '' : 'hide'}
            >
              <div className='ant-row' style={{ marginTop: '20px' }}>
                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-xl-12 highest-degree-row'>
                  <Form.Item
                    label='Highest Degree:'
                    name='highest_degree'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Highest Degree!',
                      },
                    ]}
                    style={{
                      display: 'inline-block',
                      width: 'calc(100% - 0px)',
                    }}
                  >
                    <Input
                      placeholder='Highest Degree'
                      prefix={<FaUserGraduate />}
                    />
                  </Form.Item>
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-xl-12'>
                  <Form.Item
                    label='Institution Name:'
                    name='institution'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Institution Name!',
                      },
                    ]}
                    style={{
                      display: 'inline-block',
                      width: 'calc(100% - 0px)',
                    }}
                  >
                    <Input
                      placeholder='Institution Name'
                      prefix={<FaSchool />}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className='ant-row display-flex'>
                <div
                  className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-xl-12'
                  // style={{ marginTop: '7.3px' }}
                >
                  <Form.Item
                    label='Year of Graduation:'
                    name='graduation_year'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your graduation year!',
                      },
                    ]}
                    style={{
                      display: 'inline-block',
                      width: 'calc(100% - 0px)',
                    }}
                  >
                    <DatePicker picker='year' style={{ width: '98%' }} />
                  </Form.Item>
                </div>
                <div
                  className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-xl-12'
                  // style={{ marginTop: '7.3px' }}
                >
                  <Form.Item
                    label='Experience in Months:'
                    name='experience'
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your experience!',
                      },
                    ]}
                    style={{
                      display: 'inline-block',
                      width: 'calc(100% - 0px)',
                    }}
                  >
                    <InputNumber
                      min={1}
                      max={120}
                      placeholder='Experience in Months'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Role / Designation:'
                  name='designation'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Designation!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input
                    placeholder='Role / Designation'
                    prefix={<GrUserManager />}
                  />
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Top three prof. skills:'
                  name='prof_skills'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your professional skills!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <TextArea
                    placeholder='Top three professional skills'
                    rows={1}
                    maxLength={100}
                    prefix={<IoCodeWorkingOutline />}
                  />
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Top five most used tools:'
                  name='most_used_tools'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your used tools!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <TextArea
                    placeholder='Top five most used tools'
                    rows={1}
                    maxLength={100}
                    prefix={<IoCodeWorkingOutline />}
                  />
                </Form.Item>
              </div>
            </div>

            <div
              className={steps[current].content === 'Last-page' ? '' : 'hide'}
            >
              <Result
                icon={<SmileOutlined />}
                title='Great, we have done all the operations!'
              />
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  label='Cover Letter:'
                  name='message'
                  rules={[
                    {
                      required: true,
                      message: 'Please write Cover Letter!',
                    },
                  ]}
                >
                  <Upload
                    {...props}
                    maxCount={1}
                    beforeUpload={file => {
                      const reader = new FileReader();
                      reader.readAsText(file);
                      return false;
                    }}
                  >
                    <Button
                      style={{
                        background: 'var(--primary-color)',
                        borderColor: 'var(--primary-color)',
                      }}
                      type='primary'
                    >
                      SELECT
                    </Button>
                  </Upload>
                </Form.Item>
              </div>

              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24 '>
                <Form.Item
                  label='Upload CV/Resume'
                  name='document'
                  rules={[
                    {
                      required: true,
                      message: 'Please upload your document!',
                    },
                  ]}
                >
                  <Upload
                    {...props}
                    maxCount={1}
                    beforeUpload={file => {
                      const reader = new FileReader();
                      reader.readAsText(file);
                      return false;
                    }}
                  >
                    <Button
                      style={{
                        background: 'var(--primary-color)',
                        borderColor: 'var(--primary-color)',
                      }}
                      type='primary'
                    >
                      SELECT
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={isSending}
                    block
                    size='large'
                    style={{
                      background: 'var(--primary-color)',
                      borderColor: 'var(--primary-color)',
                    }}
                  >
                    SEND
                  </Button>
                </Form.Item>
              </div>
            </div>

            <>
              <div className='steps-action'>
                {current > 0 && (
                  <Button
                    style={{
                      marginRight: '8px',
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
                {current === steps.length - 1 && ''}
                {current < steps.length - 1 && (
                  <Button type='primary' onClick={() => next()}>
                    Next
                  </Button>
                )}
              </div>
            </>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ApplyJobForm;
