import React, { useState, useEffect } from 'react';
import './HeroSlider.css';
import Particles from 'react-tsparticles';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Autoplay } from 'swiper';
import LandingHero from '../LandingPage/LandingHero';

const HeroSlider = () => {
  const [data, setData] = useState(null);
  const particlesInit = main => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/slider-data`).then(res => {
      setData(res.data);
    });
  }, []);

  const particlesLoaded = container => {};

  return (
    <div>
      <Particles
        id='tsparticles'
        init={particlesInit}
        loaded={particlesLoaded}
        canvasClassName='example'
        style={
          {
            // zIndex: `777 !important`,
            // fontSize: `100px `,
          }
        }
        options={{
          background: {
            color: {
              value: '',
              repeat: 'no-repeat',
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: 'push',
              },
              onHover: {
                enable: false,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.5,
                size: 10,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 130,
              enable: true,
              opacity: 0.5,
              width: 2,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 3,
            },
          },
          detectRetina: true,
        }}
      />

      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={true}
        // speed={3000}
        // autoplay={{
        //   delay: 15000,
        // }}
        navigation={false}
        modules={[Pagination, Autoplay]}
        className='mySwiper'
      >
        {data &&
          data.map(e => (
            <SwiperSlide key={e._id}>
              <LandingHero
                HeroIllustration={
                  process.env.REACT_APP_API_KEY_WITHOUT_V1 + '/' + e.image
                }
                sliderTitle={e.item_title}
                sliderSubTitle={e.item_description}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
