import React from 'react';
import './TechnologyHeading.css';
import { Typography } from 'antd';
// import WebBanner from '../../../../assets/developmentTools/web_banner.png';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

const { Title } = Typography;
const TechnologyHeading = props => {
  const technologyHeaderStyle = {
    background: 'url(' + props.bgImg + ')',
    backgroundRepeat: 'no-repeat no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  // const player = document.querySelector('lottie-player');
  // player.addEventListener('rendered', e => {
  //   player.load(props.technologyIllustration);
  // });

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: props.technologyIllustration,
  //   renderer: 'svg',
  // };

  return (
    <>
      <div className='TechnologyHeading' style={technologyHeaderStyle}>
        <div className='TechnologyHeading_wrapper container'>
          <div className='ant-row'>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='TechnologyHeading_heading'>
                <Title level={1}>
                  <span style={{ color: 'var(--primary-color)' }}>
                    {props.first_title}
                  </span>{' '}
                  <br /> {props.second_title}
                </Title>
              </div>

              <div className='hero_container_sub-heading'>
                <Title level={4}>{props.subTitle}</Title>
              </div>
              <div className='hero_container_buttons'>
                <Link to='/contact'>
                  <div className='hero-contact-btn'>Send Request</div>
                </Link>
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='TechnologyHeading_banner_img'>
                {/* <img src={props.technologyIllustration} alt='' /> */}

                {/* <Lottie options={defaultOptions} height={400} width={'100%'} /> */}

                <lottie-player
                  autoplay
                  loop
                  mode='normal'
                  src={props.technologyIllustration}
                  style={{ height: '400px' }}
                ></lottie-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnologyHeading;
