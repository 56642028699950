import React, { useState, useRef, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import './CounterContainer.css';

const CounterContainer = () => {
  const [memberCounter, setMemberCounter] = useState(0);
  const [projectsCounter, setProjectsCounter] = useState(0);
  const [hoursCounter, setHoursCounter] = useState(0);

  const elementRef = useRef();

  const TotalMembers = 50;
  const TotalProjects = 71;
  const TotalWorks = 1920;

  useEffect(() => {
    if (memberCounter < TotalMembers) {
      const timeout = setTimeout(() => {
        setMemberCounter(memberCounter + 1);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [memberCounter]);

  useEffect(() => {
    if (projectsCounter < TotalProjects) {
      const timeout = setTimeout(() => {
        setProjectsCounter(projectsCounter + 1);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [projectsCounter]);

  useEffect(() => {
    if (hoursCounter < TotalWorks) {
      const timeout = setTimeout(() => {
        setHoursCounter(hoursCounter + 10);
      }, 10);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [hoursCounter]);

  return (
    <>
      <Fade cascade top>
        <div className='CounterContainer' ref={elementRef}>
          <div className='CounterContainer_wrapper container'>
            <div className='ant-row'>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>{memberCounter} +</div>

                <div className='couner_text'>Team Member</div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>{projectsCounter} +</div>
                <div className='couner_text'>Projects Completed</div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>{hoursCounter} +</div>
                <div className='couner_text'>Hours of Work</div>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-6 ant-col-xl-6'>
                <div className='couner_number'>40 +</div>
                <div className='couner_text'>Happy Clients</div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default CounterContainer;
