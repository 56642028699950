import RequirementAnalysis from '../assets/LandingPage/OurStandardPracticesIcons/RequirementAnalysis.png';
import UIUX from '../assets/LandingPage/OurStandardPracticesIcons/UI-UX.png';
import Prototype from '../assets/LandingPage/OurStandardPracticesIcons/Prototype.png';
import Development from '../assets/LandingPage/OurStandardPracticesIcons/Development.png';
import Deploy from '../assets/LandingPage/OurStandardPracticesIcons/Deploy.png';
import TechnicalSupport from '../assets/LandingPage/OurStandardPracticesIcons/Technical.png';

export const OurStandardPracticeData = [
  {
    id: 1,
    iconUrl: RequirementAnalysis,
    title: 'Requirement Analysis',
  },
  {
    id: 2,
    iconUrl: UIUX,
    title: 'UI/UX Design',
  },
  {
    id: 3,
    iconUrl: Prototype,
    title: 'Prototype',
  },
  {
    id: 4,
    iconUrl: Development,
    title: 'Development ',
  },
  {
    id: 5,
    iconUrl: Deploy,
    title: 'Deploy',
  },
  {
    id: 6,
    iconUrl: TechnicalSupport,
    title: 'Technical Support',
  },
];
