import React, { useState, useEffect } from 'react';
import './MissionAndVison.css';
import axios from 'axios';

import Mission from '../../../../assets/aboutus/mission.png';
import Vision from '../../../../assets/aboutus/vision.png';
import WhoWe from '../../../../assets/aboutus/who-we.png';

const MissionAndVison = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/about-us`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      <div className='MissionAndVison'>
        <div className='MissionAndVison_wrapper container'>
          {/* ---- */}
          {data &&
            data
              .filter((i, customIndex) => i.item_order === customIndex + 1)
              .sort((a, b) => a.item_order - b.item_order)
              .map((e, index) => (
                <div className='ant-row who_we_are' key={index}>
                  <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
                    <div className='MissionAndVison_image'>
                      <div className='who-we-are-img'>
                        <img
                          alt={e.item_title + ' - AITC image'}
                          src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${e.image}`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
                    <div className='MissionAndVison_header'>{e.item_title}</div>
                    <div className='MissionAndVison_text'>
                      {/* {e.item_description} */}

                      <div
                        dangerouslySetInnerHTML={{
                          __html: e.item_description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          {/* ----- */}

          {/* <div className='ant-row mission-row' style={{ marginTop: '80px' }}>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='MissionAndVison_header'>Our Mission</div>
              <div className='MissionAndVison_text'>
                Our mission is to provide our customers with high-quality and
                affordable software development and IT consulting services. We
                combine rich management experience with a large number of
                high-quality software experts to provide you with first-class
                and cost-effective services. <br /> <br />
                We are committed to helping people and companies around the
                world reach their full potential. This is our mission.
                Everything we do reflects this mission and the values ​​that
                make it possible.
              </div>
            </div>

            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='MissionAndVison_image'>
                <img src={Mission} alt='' />
              </div>
            </div>
          </div> */}

          {/* --- */}

          {/* <div className='ant-row vision-row' style={{ marginTop: '80px' }}>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='Vison_image'>
                <img src={Vision} alt='' />
              </div>
            </div>
            <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
              <div className='MissionAndVison_header'>Our Vision</div>
              <div className='MissionAndVison_text'>
                Our mission is to provide our customers with high-quality and
                affordable software development and IT consulting services. We
                combine rich management experience with a large number of
                high-quality software experts to provide you with first-class
                and cost-effective services. <br /> <br />
                We are committed to helping people and companies around the
                world reach their full potential. This is our mission.
                Everything we do reflects this mission and the values ​​that
                make it possible.
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MissionAndVison;
