import React, { useState, useEffect } from 'react';
import './ServicesRows.css';
import { Typography, Divider } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const ServicesRows = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/stack`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div>
      <div className='service_rows_and_column'>
        <div className='service_rows_and_column_wrapper container'>
          {data &&
            data.map(e => (
              <div className='ant-row odd_and_even_seperator'>
                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
                  <Link
                    to={`/technology/${e.title
                      .replace(/\s+/g, '-')
                      .toLowerCase()}`}
                  >
                    <div className='service_rows_and_column_text'>
                      <Title level={2}>{e.title}</Title>

                      <Title level={5}>{e.short_description}</Title>
                    </div>
                  </Link>
                </div>

                <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-12 ant-col-lg-12 ant-col-xl-12'>
                  <div className='service_rows_and_column_illustration'>
                    <lottie-player
                      autoplay
                      loop
                      mode='normal'
                      src={`${process.env.REACT_APP_API_KEY_WITHOUT_V1}/${e.HeaderImg}`}
                      style={{ height: '200px' }}
                    ></lottie-player>
                  </div>
                </div>
                <div className='container'>
                  <Divider dashed />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesRows;
