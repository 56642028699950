import React from 'react';
import { Link } from 'react-router-dom';
import './Jobscard.css';

const Jobscard = props => {
  return (
    <>
      <div className='Jobscard'>
        <div className='Jobscard_wrapper'>
          <div className='Jobscard_heading'>{props.title}</div>
          <div className='Jobscard_time'> {props.time}</div>
          <div className='Jobscard_location'>{props.location}</div>
          <Link to={props.link}>
            <div className='Jobscard_more_details'>View Details &#8594;</div>{' '}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Jobscard;
