import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import './VacancyResult.css';

import { IoMailOutline } from 'react-icons/io5';
import axios from 'axios';
import Logo from '../../assets/logo.png';

const VacancyResult = () => {
  const [form] = Form.useForm();
  const [responseData, setResponseData] = useState('');
  const [isSending, setIsSending] = useState(false);

  const ContactUrl = `${process.env.REACT_APP_API_KEY}/vacancy-data/result`;
  // const ContactUrl = 'http://localhost:3000/v1/vacancy-data/result';

  const onFinish = values => {
    setIsSending(true);

    axios
      .post(ContactUrl, {
        email: values.email,
      })
      .then(response => {
        setResponseData(response.data);
        // message.success(response);
        form.resetFields();
        setIsSending(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        message.error('Error in checking your status!');
        setIsSending(false);
      });
  };

  return (
    <>
      <div id='VacancyResult'>
        <div className='VacancyResult_wrapper'>
          <div className='logo'>
            <img src={Logo} alt='' />
          </div>
          <div className='form-heading'>
            <p>Check out your application status</p>
          </div>
          <Form
            form={form}
            name='contact form'
            initialValues={{
              remember: true,
            }}
            layout='vertical'
            requiredMark={false}
            onFinish={onFinish}
          >
            <div className='ant-row'>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Please enter your email!',
                    },
                  ]}
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 0px)',
                  }}
                >
                  <Input placeholder='Your Email' prefix={<IoMailOutline />} />
                </Form.Item>
              </div>
              <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-xl-24'>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={isSending}
                    block
                    size='large'
                    style={{
                      background: 'var(--primary-color)',
                      borderColor: 'var(--primary-color)',
                    }}
                  >
                    Check Status
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>

        <div className='result-message'>
          {responseData && responseData.status === 'selected' ? (
            <p style={{ color: 'green' }}>
              Congratulations! Your application has been selected!
            </p>
          ) : responseData.status === 'not-selected' ? (
            <p style={{ color: 'red' }}>
              Your application has been not been selected!
            </p>
          ) : responseData.status === 'processing' ? (
            <p style={{ color: '#000' }}>Your application is under processing!</p>
          ) : responseData.message === 'Invalid Email.' ? (
            <p style={{ color: 'red' }}>Invalid email.</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default VacancyResult;
