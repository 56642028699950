import React, { useState, useEffect } from 'react';
import { BlogCard } from '../../components/UI';
import './Blog.css';
import { Typography, Skeleton, Divider } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const { Title } = Typography;

const Blog = () => {
  const [data, setData] = useState(null);
  const [loading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs/latest-one`).then(res => {
      // axios.get(`http://localhost:3000/v1/blogs/latest-one`).then(res => {
      setData(res.data);
    });
  }, []);

  // const titlee = data?.title.split(' ');
  // const lastTitle = titlee[titlee.length - 1];
  // const restTitle = titlee.slice(0, titlee.length - 1);
  // const title = restTitle.join(' ') + ' ' + lastTitle;
  // console.log(title);

  return (
    <div id='Blog_page'>
      <Helmet>
        <title>{data?.title}</title>
        <meta
          name='description'
          content={
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          }
          data-react-helmet='true'
        />
        <meta
          name='keywords'
          content='Business software development companies ,software development company,outsourcing software development companies, offshore software development, software outsourcing company, software outsourcing companies in uae, software outsourcing companies in usa, software outsourcing companies in australia, software outsourcing companies in Nepal, software projects outsourcing companies in australia, software projects outsourcing companies in Nepal, software projects outsourcing companies in usa, software projects outsourcing companies in Nepal, it outsourcing companies in uae, it outsourcing companies in usa, it outsourcing companies in Nepal, it outsourcing companies in australia, software development companies in usa, software development companies in uae, software development companies in Nepal, software development companies in australia, software companies, it companies dubai uae, mobile app development companies in uae, mobile app development companies in usa, mobile app development companies in australia, mobile app development companies in Nepal, mobile application development companies in uae, mobile application development companies in usa, mobile application development companies in Nepal, mobile application development companies in australia, best software development companies in uae, android application development companies in usa, mobile app development companies, app development companies india, software development, web application development, web development company, software outsourcing, business solutions, app development companies, mobile application development, android app development, school software solutions, it companies, software development dubai, software development uae'
          data-react-helmet='true'
        />

        <meta
          property='og:image'
          content={data?.image}
          data-react-helmet='true'
        />
      </Helmet>

      <div
        className='Blog_page_wrapper container'
        style={{ marginTop: '40px' }}
      >
        <div className='blog_page_banner_card '>
          <div className='blog_page_banner_card_wrapper'>
            <div className='ant-row'>
              {data === null ? (
                <Skeleton loading={loading} active />
              ) : (
                <>
                  <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-12 ant-col-xl-12'>
                    <Link
                      to={`/article/${data?.title
                        .replace(/\s+/g, '-')
                        .replace(/\./g, '')
                        .replace(/;/g, '')
                        .toLowerCase()}-${data?.id}`}
                    >
                      <Title level={1}>
                        {data?.title}
                        <span style={{ color: 'var(--primary-color)' }}>
                          {' '}
                          {/* {data?.title.split(' ').pop()} */}
                        </span>
                      </Title>

                      <Title level={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.description.slice(0, 250) + ' ...',
                          }}
                        />
                      </Title>
                    </Link>

                    <div className='career_link'>
                      <Link
                        to={`/article/${data?.title
                          .replace(/\s+/g, '-')
                          .replace(/\./g, '')
                          .replace(/;/g, '')
                          .toLowerCase()}-${data?.id}`}
                      >
                        <div className='hero-contact-btn'>
                          Read more &#8594;
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className='ant-col ant-col-xs-24 ant-col-sm-24  ant-col-md-24 ant-col-lg-12 ant-col-xl-12'>
                    <img
                      src={
                        `${process.env.REACT_APP_API_KEY_WITHOUT_V1}/` +
                        data?.image
                      }
                      alt=''
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <Divider dashed />
      </div>

      <BlogCard />
    </div>
  );
};

export default Blog;
