import './BlogCard.css';
import React, { useState, useEffect } from 'react';
import { Typography, Skeleton, Card, Row, Col } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const { Title } = Typography;
const BlogCard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/blogs`).then(res => {
      // axios.get(`http://localhost:3000/v1/blogs/`).then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div id='Blog'>
      <div className='service_rows_and_column'>
        <div className='service_rows_and_column_wrapper container'>
          {data === null ? (
            <>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <Card style={{ width: 350, marginTop: 16 }}>
                    <Skeleton loading={loading} active></Skeleton>{' '}
                  </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <Card style={{ width: 350, marginTop: 16 }}>
                    <Skeleton loading={loading} active></Skeleton>{' '}
                  </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <Card style={{ width: 350, marginTop: 16 }}>
                    <Skeleton loading={loading} active></Skeleton>{' '}
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className='blog_card'>
                <div className='ant-row'>
                  {' '}
                  {data &&
                    data
                      .sort((a, b) => b.id - a.id)
                      .filter(name => name.title !== undefined)
                      .slice(1)
                      .map(e => (
                        <div
                          className='ant-col ant-col-xs-24 ant-col-sm-12  ant-col-md-8 ant-col-lg-8 ant-col-xl-8'
                          key={e.id}
                        >
                          <div className='individual_blog_card'>
                            <Link
                              to={`/article/${e.title
                                .replace(/\s+/g, '-')
                                .replace(/\./g, '')
                                .replace(/;/g, '')
                                .toLowerCase()}-${e.id}`}
                            >
                              <div className='blog_card_img'>
                                <img
                                  src={
                                    `${process.env.REACT_APP_API_KEY_WITHOUT_V1}/` +
                                      e.image ||
                                    'https://via.placeholder.com/150'
                                  }
                                  alt=''
                                />
                              </div>
                              <div
                                className='blog_card_wrapper'
                                style={{ marginTop: '20px' }}
                              >
                                <Title level={3}>
                                  {e.title.length < 50
                                    ? e.title
                                    : e.title.slice(0, 60) + '...'}
                                </Title>
                              </div>

                              <div className='card_date_category'>
                                <div className='card_date'>
                                  Date :{' '}
                                  <Moment format='DD MMM, YYYY'>
                                    {data?.date}
                                  </Moment>{' '}
                                </div>
                                <div className='card_category'>
                                  {e.category}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
