import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LetsConnect.css';
import axios from 'axios';

const LetsConnect = () => {
  const [webSetting, setWebSetting] = useState(null);

  const location = useLocation();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_KEY}/web-settings`).then(res => {
      setWebSetting(res.data);
    });
  }, []);
  return (
    <>
      {location.pathname === '/contact' ? (
        <> </>
      ) : (
        <>
          {' '}
          <div className='lets_connect_in_footer container'>
            <div className='lets_connect_footer_banner  '>
              <div className='lets_connect_footer_banner_wrapper container '>
                <div className='lets_connect_footer_banner_heading'>
                  Let’s Connect
                </div>
                <div className='lets_connect_footer_banner_subheading'>
                  “
                  {webSetting === null
                    ? webSetting
                    : webSetting[0].lets_connect_box_subheading}{' '}
                  “
                </div>

                <Link to='/contact'>
                  <div className='hero-contact-btn'>Contact Us</div>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LetsConnect;
