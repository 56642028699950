import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/layout/ScrollToTop/ScrollToTop';
import {
  AboutUs,
  ApplyJob,
  Blog,
  BlogPage,
  Career,
  ContactUs,
  Home,
  OurTechnologies,
  OurWork,
  Services,
  VacancyResult,
} from './pages';

import NotFound from './components/UI/404Page/404Page';
import { PrivacyPolicy, TermsAndCondition } from './components/UI';

const routesInfo = [
  { path: '/', component: Home },
  { path: '/our-work', component: OurWork },
  { path: '/contact', component: ContactUs },
  { path: '/about', component: AboutUs },
  { path: '/services', component: Services },
  { path: '/technology', component: OurTechnologies },
  { path: '/career', component: Career },
  { path: '/career/:name', component: ApplyJob },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-conditions', component: TermsAndCondition },
  { path: '/result', component: VacancyResult },
  { path: '/blog', component: Blog },
  { path: '/blog/:_id:title', component: BlogPage },

  { path: '*', component: NotFound },
];

const pageRoutes = () => (
  <Routes>
    {routesInfo.map((route, index) => {
      const Component = route.component;
      return (
        <Route
          key={route + index}
          exact
          path={route.path}
          element={<Component />}
        />
      );
    })}
  </Routes>
);
pageRoutes();

const Routess = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/our-work' element={<OurWork />} />
      <Route path='/contact' element={<ContactUs />} />
      <Route path='/about' element={<AboutUs />} />
      <Route path='/result' element={<VacancyResult />} />
      <Route path='/services' element={<Services />} />
      <Route path='/career' element={<Career />} />
      <Route path='/blog' element={<Blog />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/terms-conditions' element={<TermsAndCondition />} />
      <Route path='/career/:name' element={<ApplyJob />} />
      <Route path='/technology' element={<OurTechnologies />}>
        <Route path='/technology/:title' element={<OurTechnologies />} />
      </Route>
      <Route path={`/article/:title-:id`} element={<BlogPage />} />

      <Route element={<pageRoutes />} />

      <Route path='*' element={<NotFound />} />
    </Routes>
    <ScrollToTop />
  </>
);

export default Routess;
