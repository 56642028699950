import React from 'react';
import './IndividualCard.css';

const IndividualCard = props => {
  return (
    <>
      <div className='IndividualCard'>
        <div className='IndividualCard_wrapper'>
          <div className='IndividualCard_text_field container'>
            <div className='IndividualCard_name'>{props.name}</div>

            <div className='IndividualCard_info'>{props.position}</div>
          </div>

          <div className='IndividualCard_img'>
            <img src={props.profile_pic} alt='' />
          </div>

          <div className='overlay'>
            <div className='overlay_wrapper'>
              <div className='overlay_img'>
                <img src={props.profile_pic} alt='' />
              </div>

              <div className='overlay_IndividualCard_text_field '>
                <div className='overlay_IndividualCard_name'>{props.name}</div>

                <div className='overlay_IndividualCard_info'>
                  {props.position}
                </div>

                <div className='overlay_IndividualCard_description'>
                  "{props.description}"
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualCard;
