import React from 'react';
import './ContactPageHeaderImg.css';

const ContactPageHeaderImg = () => {
  return (
    <>
      <div className='ContactPageHeaderImg'>
        <div className='contact_heading_text container'>
          <h1>Contact Us</h1>
          <p>Have a Project? We’d love to hear from you</p>
        </div>
        {/* <img src={ContactPageBg} alt='' /> */}
      </div>
    </>
  );
};

export default ContactPageHeaderImg;
